@font-face {
  font-family: 'HK Grotesk';
  src: url('./assets/fonts/hk-grotesk.ttf') format('truetype');
}
@font-face {
  font-family: 'HK Grotesk Bold';
  src: url('./assets/fonts/hk-grotesk-bold.otf') format('opentype');
}

* {
  margin: 0;
}

body {
  margin: 0;
  font-family: 'HK Grotesk', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h2 {
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 30px;
  /* identical to box height, or 100% */

  text-align: center;

  /* dark */
  color: #004343;
}

p {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #004343;
}

a {
  text-decoration: none;
  color: inherit;
}

.text-pink {
  color: #DC58BF;
}

.text-bold {
  font-family: 'HK Grotesk Bold', sans-serif;
  font-weight: 700;
}

.root {
  min-height: 100vh;
  min-width: 100vw;
  padding-top: 1px;
  padding-bottom: 1px;
}

.page-container, .error-div {
  background-color: #ffffff;
  width: 70vw;
  margin: auto;
  border-radius: 20px;
  box-shadow: 0px 8px 24px rgba(149, 157, 165, 0.2);
}

.error-div {
  position: absolute;
  top: 3vh;
  z-index: 10;
  margin-left: 15vw;
  margin-right: 15vw;
}

.error-div-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
}

.cross:hover {
  cursor: pointer;
}

.page-content {
  margin-top: 15vh;
  margin-bottom: 15vh;
  padding: 4vh 5vw;
  text-align: center;
}

.input {
  width: 80%;
  max-width: 372px;
  height: 48px;
  padding-left: 10px;
  padding-right: 10px;

  background: #F7F7F7;
  border-radius: 100px;
  border: none;

  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 30px;
  /* identical to box height, or 100% */

  text-align: center;

  /* dark */
  color: #004343;
}

.button {
  min-width: 200px;
  min-height: 60px;

  /* turquoise */

  background: #00A0AF;
  border-radius: 100px;
  border: none;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.02em;

  /* white */

  color: #FFFFFF;
}

.button:hover {
  cursor: pointer;
}

.button-disabled {
  background: #99E1E7;
}
.button-disabled:hover {
  cursor: default;
}

.button-loading {
  width: 200px;
  min-height: 60px;

  background: #FFFFFF;
  border: 1px solid #00A0AF;
  color: #004343;
  border-radius: 100px;
  cursor: default !important;
}

.button-container {
  margin-top: 2vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.checkbox-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 1vh;
  margin-bottom: 1vh;
}

input[type=checkbox] {
  -moz-appearance:none;
  -webkit-appearance:none;
  -o-appearance:none;
  outline: none;
  content: none;
}

input[type=checkbox]:before {
  background-image: url('./assets/img/check.svg');
  background-repeat: no-repeat;
  background-position: center;

  content: "";
  font-size: 15px;
  color: transparent !important;
  display: block;
  width: 24px;
  height: 24px;
  border: 1px solid #00A0AF;
  border-radius: 2px;
  margin-right: 7px;
}

input[type=checkbox]:checked:before {
  background-color: #00A0AF;
  color: white !important;
}

.bank-info-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.bank-info-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  flex-wrap: wrap;
}

.bank-info-name {
  margin-right: 1vw;
  width: 15%;
}

.bank-info-input {
  background-color: #F7F7F7;
}

@media screen and (max-width: 800px) {
  .page-container, .error-div {
    width: 85vw;
  }

  .error-div {
    margin-left: 7.5vw;
    margin-right: 7.5vw;
  }

  .bank-info-name {
    width: 20%;
    margin-right: 5vw;
  }
}

@media screen and (max-width: 550px) {
  .bank-info-container {
    width: 100%;
  }

  .bank-info-row {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 20px;
    flex-wrap: wrap;
  }

  .bank-info-name {
    width: 100%;
    text-align: center;
    margin-bottom: 5px;
  }
  .bank-info-input {
    width: 90% !important;
    max-width: 90% !important;
  }

  .button-container {
    flex-direction: column;
  }
}
@media screen and (max-width: 280px){
  .button-container {
    margin-top: 5vh;
  }
}
